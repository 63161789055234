import React from "react";
import "./Footer.css";
import {
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiOutlineMail,
  AiOutlineYoutube,
  AiOutlineFacebook,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
function Footer() {
  const navigate = useNavigate()
  return (
    <div>
      <header>{/* Content */}</header>

      <main>{/* Content */}</main>

      <footer className="footer">
        <div className="footer__addr">
          <h1 className="footer__logo">Civil Dynamic</h1>

          <h2>Contact</h2>

          <address>
            Plot No: 363, Near Tamando Overbridge Bijipur
            <br />
            Bhubaneswar, Odisha-752054
            <br />
            <div className="flex gap-2 p-4 cursor-pointer">
              <a href="https://wa.me/9124732888" target="_blank">
                <AiOutlineWhatsApp className="socialmedia-icons" />
              </a>

              <a
                href="https://www.instagram.com/civil_dynamic/"
                target="_blank"
              >
                <AiOutlineInstagram className="socialmedia-icons" />
              </a>
              <a href="mailto:civildynamic1@gmail.com" target="_blank">
                <AiOutlineMail className="socialmedia-icons" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61552953963690"
                target="_blank"
              >
                <AiOutlineFacebook className="socialmedia-icons" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC27fZEy5Z1TPjag6MvC_Zaw"
                target="_blank"
              >
                <AiOutlineYoutube className="socialmedia-icons" />
              </a>
            </div>
          </address>
        </div>

        <ul className="footer__nav">
          <li className="nav__item">
            <h2 className="nav__title">Media</h2>

            <ul className="nav__ul">
              <li>
                <a href="#">Structural Engineering</a>
              </li>

              <li>
                <a href="#">GeoTech Management</a>
              </li>

              <li>
                <a href="#">Construction Mgmt</a>
              </li>
              <li>
                <a href="#">Env Engineering</a>
              </li>
              <li>
                <a href="#">Survey & Mapping</a>
              </li>
            </ul>
          </li>

          <li className="nav__item nav__item--extra">
            <Link to={'/about'} className="nav__title">About Us</Link>

            <ul className="nav__ul nav__ul--extra">
              <li>
                <Link to={'/'}>Mission & Vision</Link>
              </li>

              <li>
                <Link to={'/'}>Instructors</Link>
              </li>

              <li>
                <Link to={'/contact'}>Contact Us</Link>
              </li>
            </ul>
          </li>

          <li className="nav__item">
            <h2 className="nav__title">Legal</h2>

            <ul className="nav__ul">
              <li>
                <a href="#">Privacy Policy</a>
              </li>

              <li>
                <a href="#">Terms of Use</a>
              </li>

              <li>
                <a href="#">Sitemap</a>
              </li>
            </ul>
          </li>
        </ul>

        <div className="legal">
          <p>&copy; 2023 Civil Dynamic. All rights reserved.</p>

          <div className="legal__links">
            <span>
              Made with <span className="heart">♥</span> to build a strong civil
              community.
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
