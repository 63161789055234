import "tailwindcss/tailwind.css";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home";
import { Routes, Route } from "react-router-dom";
import CourseDetails from "./components/CourseDetails/CourseDetails";
import Contact from "./components/ContactUs/Contact";
import AboutUs from "./components/AboutUsPage/AboutUs";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Footer from "./components/Footer/Footer";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/course-page" element={<CourseDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<AboutUs/>}/>
      </Routes>
      <Footer />
      <ToastContainer position="top-center" className="lg:w-1/2 xl:w-1/3 sm:w-full"/>
    </div>
  );
}

export default App;
