import React from "react";
import { BsBuildings } from "react-icons/bs";
import CourseCard from "./CourseCard";

const Courses = () => {
  const courseArr = [
    {
      id: 1,
      icon: <BsBuildings className="course-card-icon" />,
      heading: "Personalized Guidance",
      price: 12000,
      content:
        "Providing one-on-one coaching tailored to each student's specific needs and learning pace is crucial, as it ensures that students receive individualized support and have a better chance of succeeding.",
    },
    {
      id: 2,
      icon: <BsBuildings className="course-card-icon" />,
      heading: "Experienced Instructors",
      price: 12000,
      content:
        "Having experienced instructors is vital, as their expertise and knowledge will greatly benefit the students. Experienced professionals can offer valuable insights and guidance",
    },
    {
      id: 3,
      icon: <BsBuildings className="course-card-icon" />,
      heading: "Customized Curriculum",
      price: 12000,
      content:
        "Offering a curriculum that aligns with the academic requirements and career goals of the students is essential. A tailored approach ensures that the learning experience is relevant and focused on the student's objectives.",
    },
  ];

  return (
    <div className="mt-32 py-[30px] lg:px-[30px] md:px-[60px] px-[30px]">
      <h1
        style={{ color: "var(--orange-color)" }}
        className="p-2 capitalize text-center mb-16 md:mb-10  text-2xl sm:text-2xl xl:text-4xl font-extrabold"
      >
       Why Civil Dynamic ?
      </h1>
      <div className="flex justify-center flex-wrap gap-10">
        {courseArr.map((item) => (
          <CourseCard key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};

export default Courses;
