export const testimonialData = [
  {
    name: "Amit Kumar",
    role: "Civil Engineering Student",
    imageSrc: "https://secure.gravatar.com/avatar/fe53264b3ea357c295e5f3df6530c65e?s=45&d=mm&r=g",
    content: "Enrolling in this offline training center has been a game-changer for my civil engineering preparation. The instructors here are experts in their field, and the hands-on approach to learning has significantly improved my understanding of complex concepts. The collaborative environment among students is motivating and enhances the overall learning experience.",
  },
  {
    name: "Priya Patel",
    role: "Civil Services Aspirant",
    imageSrc: "https://secure.gravatar.com/avatar/fe53264b3ea357c295e5f3df6530c65e?s=45&d=mm&r=g",
    content: "I am grateful for the guidance and support I've received at this offline training center. The comprehensive curriculum, combined with personalized coaching, has prepared me well for civil services exams. The regular test series and feedback sessions have been instrumental in refining my exam-taking skills. I highly recommend this center for aspiring civil servants.",
  },
  {
    name: "Rahul Singh",
    role: "Structural Engineering Enthusiast",
    imageSrc: "https://secure.gravatar.com/avatar/fe53264b3ea357c295e5f3df6530c65e?s=45&d=mm&r=g",
    content: "Being a part of this offline training center has been a fulfilling experience for my structural engineering aspirations. The practical approach to teaching, coupled with real-world examples, has deepened my understanding of structural concepts. The dedicated faculty and well-equipped facilities make this center an ideal choice for anyone pursuing a career in structural engineering.",
  },
];
