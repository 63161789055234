import axios from "axios";
import { toast } from "react-toastify";
function isNameValid(name) {
  return name.trim() !== "";
}
function isEmailValid(email) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email) && email.endsWith(".com");
}

function isPhoneNumberValid(phoneNumber) {
  const phonePattern = /^\d{10}$/;
  return phonePattern.test(phoneNumber);
}

function isPasswordValid(password) {
  const passwordPattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  return passwordPattern.test(password);
}

export async function handleSignupform(
  e,
  setError,
  SignupData,
  setLoading,
  setshowSignupModal
) {
  e.preventDefault();

  if (!isNameValid(SignupData.name)) {
    setError("Please enter your name.");
    return;
  }

  if (!isEmailValid(SignupData.email)) {
    setError("Please enter a valid email address.");
    return;
  }

  if (!isPhoneNumberValid(SignupData.phone)) {
    setError("Please enter a valid 10-digit phone number.");
    return;
  }
  if (!isPasswordValid(SignupData.password)) {
    setError(
      "Password: 8+ characters with 1 uppercase, 1 lowercase, 1 digit, and 1 special character."
    );
    return;
  }
  setError("");
  e.target.reset();
  setLoading(true);
  try {
    const response = await axios.post(
      "http://localhost:8000/signup",
      SignupData
    );
    console.log(response);
    toast.success("You have successfully signed up!");
    setshowSignupModal(false);
  } catch (err) {
    console.error(err);
    toast.error("Signup failed. Please try again.");
  }
  setLoading(false);
}
