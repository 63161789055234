export const reviewsData = [
    {
      id: 1,
      name: "Rahul Singh",
      profileImage: "https://secure.gravatar.com/avatar/fe53264b3ea357c295e5f3df6530c65e?s=45&d=mm&r=g",
      reviewText:
        "The course exceeded my expectations! The instructors are knowledgeable, and the hands-on projects provided practical insights. I highly recommend it to anyone aspiring to enter the field of civil engineering.",
    },
    {
      id: 2,
      name: "Priya Patel",
      profileImage: "https://secure.gravatar.com/avatar/fe53264b3ea357c295e5f3df6530c65e?s=45&d=mm&r=g",
      reviewText:
        "I'm glad I chose this course. The material is well-organized, and the instructors are passionate about teaching. The practical examples and real-world applications make it easier to grasp complex concepts.",
    },
    {
      id: 3,
      name: "Amit Kumar",
      profileImage: "https://secure.gravatar.com/avatar/fe53264b3ea357c295e5f3df6530c65e?s=45&d=mm&r=g",
      reviewText:
        "The course provides a comprehensive understanding of civil engineering principles. The hands-on exercises and industry insights are invaluable. I feel more confident in pursuing a career in this field after completing the course.",
    },
  ];