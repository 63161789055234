export const CurriculumArr = [
  {
    id: 1,
    open: false,
    title: "Layout Work",
    topics: [
      "Introduction",
      "Unit Conversion",
      "Township Layout",
      "Centre Line Layout",
    ],
  },
  {
    id: 2,
    open: false,
    title: "Building Construction & Estimation",
    topics: [
      "Building Construction Procedure",
      "Foundation & Types",
      "Sub Structure & Super Structure Parts",
      "Staircase & Staircase Tower",
      "BOQ (Bill of Quantity)",
    ],
  },
  {
    id: 3,
    open: false,
    title: "Survey",
    topics: [
      "Auto Level Setup & Total Station",
      "Land Leveling",
      "Profile Leveling",
      "Contour Leveling",
    ],
  },
  {
    id: 4,
    open: false,
    title: "Highway",
    topics: ["Flexible Pavement", "Rigid Pavement"],
  },
  {
    id: 5,
    open: false,
    title: "Quantity Survey & Analysis of Rate(A.R)",
    topics: [
      "Q.S of Concrete Work & R.A",
      "Q.S of Brick Work & R.A",
      "Q.S of Plastering & Painting Work & R.A Q.S of Flooring Work & R.A",
      "Q.S of Shuttering Work & R.A",
    ],
  },
  {
    id: 6,
    open: false,
    title: "BBS (Bar Banding Schedule)",
    topics: [
      "BBS of Footing",
      "BBS of Column & Beam BBS of Slab",
      "BBS of Staircase & Chajja BBS of Pile & Pile Cap",
    ],
  },
  {
    id: 7,
    open: false,
    title: "Billing",
    topics: [
      "Types of Billing",
      "Item wise Billing",
      "Slab Rate Billing",
      "Contract & Tendering",
    ],
  },
  {
    id: 8,
    open: false,
    title: "Auto Cad",
    topics: [
     "Auto Cad"
    ],
  },
  {
    id: 9,
    open: false,
    title: "Google Sketchup",
    topics: [
      "Google Sketchup"
    ],
  },
];
