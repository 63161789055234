import React, { useState } from "react";
import { MdEmail } from "react-icons/md";
import { BiSolidLockAlt } from "react-icons/bi";
import { HiPhone, HiUser } from "react-icons/hi";
import { FaUserCircle } from "react-icons/fa";
import "./Signup.css";
import { RxCross2 } from "react-icons/rx";
import { handleSignupform } from "../Helpers/Signuphelper";
import LoadingComponent from "../Loading/LoadingComponent";
const Signup = ({ setshowSignupModal, onClose }) => {

  const [SignupData, setSignupData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
  });
  const[loading,setLoading] = useState(false)
  const [error, setError] = useState("");

  function handleSignupValue(e) {
    if (error) {
      setError("");
    }
    const { name, value } = e.target;
    setSignupData({ ...SignupData, [name]: value });
  }

  

  return (
    <div className="signup-modal-content">
      <FaUserCircle
        size={50}
        className="m-auto mb-2"
        style={{ color: "#697584" }}
      />
      <h2
        style={{ color: "#697584" }}
        className="text-xl font-extrabold text-center mb-1"
      >
        WELCOME
      </h2>
      <p style={{ color: "#697584" }} className="text-sm text-center mb-3">
        Sign up by entering the information below
      </p>
      <button className="absolute top-1 right-1" onClick={() => onClose()}>
        {" "}
        <RxCross2 size={22} style={{ color: "orange" }} />{" "}
      </button>

      <form
        action=""
        className="signup-form flex flex-col gap-3"
        onSubmit={(e)=>handleSignupform(e,setError,SignupData,setLoading, setshowSignupModal)}
      >
        <div>
          <span>
            <HiUser size={20} style={{ color: "orange" }} />
          </span>
          <input
            type="text"
            placeholder="Name"
            name="name"
            onChange={handleSignupValue}
            required
          />
        </div>

        <div>
          <span>
            <MdEmail size={20} style={{ color: "orange" }} />
          </span>
          <input
            type="email"
            placeholder="Email"
            name="email"
            onChange={handleSignupValue}
            required
          />
        </div>

        <div>
          <span>
            <HiPhone size={20} style={{ color: "orange" }} />
          </span>
          <input
            type="tel"
            placeholder="Phone Number"
            name="phone"
            onChange={handleSignupValue}
            required
          />
        </div>

        <div>
          <span>
            <BiSolidLockAlt size={20} style={{ color: "orange" }} />
          </span>
          <input
            type="password"
            placeholder="Password"
            name="password"
            onChange={handleSignupValue}
            required
          />
        </div>

        <p className="text-red-800 text-[15px]">{error}</p>

        <button className="login-modal-btn mt-5">{ loading?<LoadingComponent width="w-[35px]" height="h-[35px]"/>:  "Get Started"}</button>
      </form>
      <div className="mt-5 text-center">
        <p style={{ color: "#697584" }}>Already have an account?</p>
        <p
          href=""
          className="signup-modal-btn"
          onClick={() => setshowSignupModal(false)}
        >
          LOG IN
        </p>
      </div>
    </div>
  );
};

export default Signup;
