import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import "../CourseDetails.css";
const Overview = () => {
  return (
    <div>
      <p className="content mt-7 font-bold lg:text-base md:text-base text-[12px]">
        Welcome to Civil Dynamic Civil & Design Training Institute! Our mission
        is to empower aspiring civil engineers and designers for a successful
        career in the industry. With a strong emphasis on practical skills and
        personalized job assistance, our program covers an extensive range of
        topics, ensuring you're well-prepared for the challenges of the field.
        <br />
        <br />
        Whether you're a recent graduate or a seasoned professional looking to
        enhance your skills, our program is tailored to meet your specific needs
        and career goals. Our instructors, industry experts with years of
        experience, are dedicated to providing you with a holistic learning
        experience.
        <br />
        <br />
        Join us at Civil Dynamic and embark on a journey towards a rewarding
        career in civil engineering and design.
      </p>
      <div className="flex flex-col gap-4 mt-5 lg:text-lg md:text-lg text-[12px]">
        <div className="flex items-center gap-3">
          <BsFillCheckCircleFill style={{ color: "var(--orange-color)" }} />{" "}
          <span className="">
            100% Job Assistance to secure your debut position.
          </span>
        </div>
        <div className="flex items-center gap-3">
          <BsFillCheckCircleFill style={{ color: "var(--orange-color)" }} />{" "}
          <span className=" ">
            Authorised Training Certificate for industry recognition.
          </span>
        </div>
        <div className="flex items-center gap-3">
          <BsFillCheckCircleFill style={{ color: "var(--orange-color)" }} />{" "}
          <span className="">On-site Learning for practical experience.</span>
        </div>
      </div>
    </div>
  );
};

export default Overview;
