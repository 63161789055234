import React, { useState } from "react";
import { CurriculumArr } from "./CurriculumData";
import { AiOutlineCaretRight } from "react-icons/ai";
import { PiStudent } from "react-icons/pi";
import "../CourseDetails.css";
const Curriculum = () => {
  const [Curriculum, setCurriculum] = useState(CurriculumArr);
  const handleOpenCurriculum = (id) => {
    const updetedCurriculum = Curriculum.map((data) =>
      data.id === id ? { ...data, open: !data.open } : data
    );
    setCurriculum(updetedCurriculum);
    console.log(updetedCurriculum);
  };

  return (
    <div>
      <h1 className="lg:text-2xl md:text-2xl text-[16px] font-extrabold mt-6">
        Civil Engineering Fundamentals: A Beginner's Guide
      </h1>
      <p className="content mt-2 font-bold lg:text-base md:text-base text-xs">
        Welcome to our Starting Beginners Level Course in Civil Engineering!
        This course is designed to provide you with a solid foundation in the
        fundamentals of civil engineering. Whether you're a novice in the field
        or looking to refresh your knowledge, we'll guide you through essential
        concepts to build a strong understanding. Explore the principles of
        structural engineering, project management, and practical applications.
        Gain insights into the world of civil engineering and lay the groundwork
        for a successful journey in this dynamic industry.
      </p>
      {Curriculum.map((data) => (
        <div
          style={{ border: "1px solid gray" }}
          className="rounded-lg mt-4 cursor-pointer"
        >
          <div
            style={
              data.open
                ? {
                    borderBottom: "1px solid gray",
                    borderBottomLeftRadius: "0",
                    borderBottomRightRadius: "0",
                  }
                : {}
            }
            className={`flex lg:py-5 md:py-5 py-2 lg:px-3 md:px-3 px-2 rounded-lg justify-between items-center hover:text-orange-400 ${
              data.open ? "bg-zinc-100 text-orange-400" : ""
            }`}
            onClick={() => handleOpenCurriculum(data.id)}
          >
            <h1 className="lg:text-xl md:text-xl text-[13px] font-extrabold">
              {data.title}{" "}
            </h1>
            <p className="flex items-center lg:gap-2 md:gap-2 gap-[2px] font-extrabold lg:text-lg md:text-lg text-xs">
              {data.topics.length} Lessons{" "}
              <AiOutlineCaretRight
                style={{ cursor: "pointer" }}
                size={20}
                color="var(--orange-color)"
              />{" "}
            </p>
          </div>
          {data.open && (
            <div>
              {data.topics.map((elm) => (
                <div className="flex justify-between items-center px-4 py-2 shadow-sm hover:bg-zinc-100 hover:border  lg:text-base md:text-base text-[12px] ">
                  <p>
                    <PiStudent style={{ display: "inline-block" }} />{" "}
                    <span>{elm}</span>
                  </p>
                  <p>1 day</p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Curriculum;
