import { AiOutlineClockCircle } from "react-icons/ai";
import {LuBookOpenCheck} from "react-icons/lu";
import {PiLightbulbFilament} from "react-icons/pi"
import {MdLanguage} from "react-icons/md"
import {BiBookReader} from "react-icons/bi"
export const courseFeaturesData = [
    {
      id: 1,
      icon: <AiOutlineClockCircle style={{ color: "var(--orange-color)" }} />,
      features: "Duration",
      time: "2 Months",
    },
    {
      id: 2,
      icon: <LuBookOpenCheck style={{ color: "var(--orange-color)" }} />,
      features: "Lessons",
      time: "9",
    },
    {
      id: 3,
      icon: <PiLightbulbFilament style={{ color: "var(--orange-color)" }} />,
      features: "Skill Level",
      time: "Advanced",
    },
    {
      id: 4,
      icon: <BiBookReader style={{ color: "var(--orange-color)" }} />,
      features: "Students",
      time: "Max 15",
    },

    {
      id: 6,
      icon: <MdLanguage style={{ color:"var(--orange-color)" }} />,
      features: "Language",
      time: "English",
    },
  ];