import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialCard from "./Card";
import { testimonialData } from "./Data";

const Testimonials = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 668);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize); // Listen for window resize events
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    // Update isMobile based on the window width
  };

  return (
    <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col items-center">
          <div className="text-center">
            <h1 style={{color:"var(--orange-color)"}} className="p-2 capitalize  md:mb-5  text-2xl sm:text-2xl xl:text-4xl font-extrabold">
              Testimonials
            </h1>
            <p className="font-bold text-gray-900 sm:text-2xl xl:text-3xl">
              Our happy clients say about us
            </p>
          </div>

          <div className="relative mt-10 md:mt-20 md:order-2">
            <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
              <div
                className="w-full h-full mx-autorounded-3xl opacity-30 blur-lg filter"
                style={{
                  background:
                    "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                }}
              ></div>
            </div>

            <div className="relative grid grid-cols-1 gap-12 lg:ml-[30px] mx-auto lg:gap-10 md:grid-cols-1">
              <Slider {...settings}>
                {testimonialData.map((testimonial, index) => (
                  <TestimonialCard key={index} {...testimonial} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
