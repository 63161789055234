import React from "react";
import "../CourseDetails.css";
import omkarjypuria from "../../../assets/omkarjypuria.jpeg";
import shalinipatel from "../../../assets/shalinipatel.jpeg";
import Gadadharmartha from "../../../assets/Gadadharmartha.jpeg";
const Instructor = () => {
  const instructorsData = [
    {
      name: "Omkar Jaypuria",
      qualifications: "BTech, MTech",
      role: "Civil Engineer",
      profileImage: omkarjypuria,
      description:
        "With a dual degree in BTech and MTech, is a seasoned Civil Engineer. His passion for structural engineering and practical insights into construction make him a valuable instructor for our program.",
    },
    {
      name: "Shalini Patel",
      qualifications: "B.Arch, M.Plan",
      role: "Architect",
      profileImage: shalinipatel,
      description:
        "With a background in Architecture and Urban Planning, Shalini Patel brings a rich blend of creativity and expertise to our courses. Her extensive knowledge in design and planning adds a unique perspective to the learning experience.",
    },
    {
      name: "Gadadhar Martha",
      qualifications: "BTech",
      role: "Civil Engineer",
      profileImage: Gadadharmartha,
      description:
        "With a BTech degree, Gadadhar Martha brings a wealth of knowledge in Civil Engineering to our courses. His expertise lies in core engineering principles, and he emphasizes real-world applications, enriching the learning journey for our students.",
    },
  ];
  return (
    <div>
      {instructorsData.map((instructor, index) => (
        <div key={index} className="flex lg:items-center md:items-center lg:gap-8 md:gap-8 gap-3 py-3 mt-7">
          <img
            className="lg:w-[180px] lg:h-[180px] md:w-[180px] md:h-[180px] w-[50px] h-[50px] rounded-full"
            src={instructor.profileImage}
            alt={`${instructor.name}'s Profile`}
          />
          <div>
            <p className="lg:text-[20px] md:text-[20px] text-[16px] font-extrabold">
             {instructor.name}
            </p>
            <p
              style={{ color: "#697584" }}
              className="lg:text-[13px] md:text-[13px] text-[13px] lg:mt-1 md:mt-1 mt-[2px]"
            >
              {instructor.role}
            </p>
            <p className="content mt-2 font-bold lg:text-base md:text-base text-[12px]">
              {instructor.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Instructor;
