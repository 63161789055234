import React from "react";
import "./CourseCard.css";
import "tailwindcss/tailwind.css";
import { BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
const CourseCard = ({ item }) => {
  const navigate = useNavigate()
  return (
    <div className="course-card-box w-[340px] overflow-hidden">
      <div className="course-detail  flex flex-col gap-4 border p-[30px]">
        <div className="course-icon-box border p-[25px] w-[90px] text-4xl text-white rounded-full">
          {item.icon}
        </div>
        <h3 className="text-2xl font-semibold">{item.heading}</h3>
        {/* <h5 className="text-lg font-semibold">₹{item.price}</h5> */}
        <p className="opacity-[.6]">{item.content}</p>
        <div>
          <p className="view-details" onClick={()=>{navigate('/course-page')}}>
            View Details{" "}
            <BiRightArrowAlt size={22} style={{ display: "inline-block" }} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
