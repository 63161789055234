import React from 'react';
import './LoadingComponent.css';

const LoadingComponent = ({height,width}) => {
  return (
    <div className={`flex justify-center items-center`}>
      <div className={`loader ${width} ${height}`}></div>
    </div>
  );
}

export default LoadingComponent;
