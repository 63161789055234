import React from "react";
import "./AboutUs.css";
import {Helmet} from "react-helmet";
const AboutUs = () => {
  return (
    <>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Civil Dynamic-About Us</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
   
    <div className="we-are-block mt-20">
      <div id="about-us-section" className="we-are-block">
        <div className="about-us-image">
          <img
            src="https://digitalupgrade.com/images/lobbyimage_1.jpg"
            width="808"
            height="458"
            alt="Lobby Image"
          />
        </div>
        <div className="about-us-info">
          <p>
            <h2>Welcome to Civil Dynamic</h2>  your premier offline
            coaching center for civil engineering students.
          </p>
          <p>
            At Civil Dynamic, we understand the unique challenges that civil
            engineering students face. Our dedicated team is committed to
            providing high-quality coaching, equipping students with the
            knowledge and skills necessary for academic excellence. Conveniently
            situated at Plot No: 363, Near Tamando Overbridge, Bijipur,
            Bhubaneswar, our center is easily accessible for students seeking
            valuable guidance and support.
          </p>
          <a href="#" title="About Us Button">
            ABOUT US
          </a>
        </div>
      </div>
      <div id="history-section" className="we-are-block">
        <div className="history-image">
          <img
            src="https://digitalupgrade.com/images/building_pic.jpg"
            width="951"
            height="471"
            alt="Building Pic"
          />
        </div>
        <div className="history-info">
          <h2>Embracing Our Journey</h2>
          <p>
          Since our inception in 2018, Civil Dynamic has undergone a remarkable journey of growth and dedication. From our humble beginnings to becoming a beacon of educational excellence, our commitment to shaping the future of civil engineering education remains unwavering.
          </p>
          <a href="#" title="History Button">
            HISTORY
          </a>
        </div>
      </div>
    </div>
    </>
  );
};

export default AboutUs;
