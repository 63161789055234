import React from "react";
import "./Aboutus.css";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
const About = () => {
  const navigate = useNavigate()
  return (
    <div className="about-main-box flex justify-between gap-5 px-24 py-16">
      <div className="about-img-container flex-[1]">
        <img
          className="about-img w-full h-full object-contain"
          src="https://img.freepik.com/free-photo/civil-engineer-construction-worker-architects-wearing-hardhats-safety-vests-are-working-together-construction-site-building-home-cooperation-teamwork-concept_640221-172.jpg"
          alt=""
        />
      </div>
      <div className="aboutus-box flex-[1] px-11 py-5">
        <h3
          style={{ color: "var(--orange-color)" }}
          className="text-3xl font-bold mb-4"
        >
          About Us
        </h3>
        <h2 className="text-[18px]">
          We are helping you secure a high-paying job with our expert-guided
          civil engineering learning platform. Unlock your potential and build a
          successful career in civil engineering today!
        </h2>
        <p className="mt-3 opacity-[0.5] text-sm">
           At Civil Dynamic, we understand the unique challenges that civil
            engineering students face. Our dedicated team is committed to
            providing high-quality coaching, equipping students with the
            knowledge and skills necessary for academic excellence. Conveniently
            situated at Plot No: 363, Near Tamando Overbridge, Bijipur,
            Bhubaneswar, our center is easily accessible for students seeking
            valuable guidance and support.
        </p>
        <button className="find-course-btn" onClick={()=>{navigate('/about')}}>
          Discover More <AiOutlineDoubleRight className="right-arrow-icon"/>
        </button>
      </div>
    </div>
  );
};

export default About;
