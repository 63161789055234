import React, { useState } from "react";
import "./Loginmodal.css";
import { MdEmail } from "react-icons/md";
import { BiSolidLockAlt } from "react-icons/bi";
import Signup from "../signup/Signup";
import { RxCross2 } from "react-icons/rx";
import { FaUserCircle } from "react-icons/fa";
import LoadingComponent from "../Loading/LoadingComponent";
import { handleLoginform } from "../Helpers/LoginHelper";

const LoginModal = ({ onClose }) => {
  const [showSignupModal, setshowSignupModal] = useState(false);
  const [LoginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  function handleLoginValue(e) {
    const { name, value } = e.target;
    setLoginData({ ...LoginData, [name]: value });
  }

  

  return (
    <div className="login-modal">
      <div className="moodalbg">
        {showSignupModal ? (
          <Signup setshowSignupModal={setshowSignupModal} onClose={onClose} />
        ) : (
          <div className="login-modal-content">
            <FaUserCircle
              size={50}
              className="m-auto mb-2"
              style={{ color: "#697584" }}
            />
            <h2
              style={{ color: "#697584" }}
              className="text-xl font-extrabold text-center mb-1"
            >
              WELCOME
            </h2>
            <p
              style={{ color: "#697584" }}
              className="text-sm text-center mb-6"
            >
              Sign in by entering the information below
            </p>
            <button
              className="absolute top-1 right-1"
              onClick={() => onClose()}
            >
              {" "}
              <RxCross2 size={22} style={{ color: "orange" }} />{" "}
            </button>
            <form
              action=""
              onSubmit={(e) => handleLoginform(e,setLoading,onClose,LoginData)}
              className="login-form flex flex-col gap-4"
            >
              <div>
                <span>
                  <MdEmail size={20} style={{ color: "orange" }} />
                </span>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleLoginValue}
                  required
                />
              </div>
              <div>
                <span>
                  <BiSolidLockAlt size={20} style={{ color: "orange" }} />
                </span>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleLoginValue}
                  required
                />
              </div>
              <p>Forgot Password?</p>
              <button className="login-modal-btn">
                {loading ? (
                  <LoadingComponent width="w-[30px]" height="h-[30px]" />
                ) : (
                  "Get Started"
                )}
              </button>
              {/* <button className="login-modal-btn">Get Started</button> */}
            </form>
            <div className="mt-10 text-center">
              <p style={{ color: "#697584" }}>Don't have an account?</p>
              <p
                className="signup-modal-btn"
                onClick={() => setshowSignupModal(true)}
              >
                SIGN UP
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginModal;
