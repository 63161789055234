import React, { useState } from "react";
import Stars from "../Testimonial/Stars";
import Overview from "./Overview/Overview";
import Curriculum from "./Curriculum/Curriculum";
import Reviews from "./Reviews/Reviews";
import Instructor from "./Instructor/Instructor";
import "./CourseDetails.css";
import { courseFeaturesData } from "./FeaturesData";
import { reviewsData } from "./ReviewData";
import { Helmet } from "react-helmet";

const buttonsData = ["Overview", "Curriculum", "Reviews", "Instructor"];
const CourseDetails = () => {
  const [active, setActive] = useState("Curriculum");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Civil Dynamic-Courses</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div className="course-detail-box mt-32 lg:ml-3 md:ml-3 lg:flex lg:justify-between  gap-8  lg:px-8 md:px-5">
        <div className="flex-1 course-detail-left-div lg:p-4 md:p-4 p-2">
          <h1 className="lg:text-3xl course-detail-heading md:text-3xl text-xl lg:mb-10 md:mb-10 mb-3  font-extrabold">
            Training Course Detail
          </h1>
          <img
            className="rounded-xl course-image w-full lg:h-[400px] md:h-[400px] h-[150px]"
            src="https://bracketweb.com/eduactwp/wp-content/uploads/2019/05/course-detail-1.jpg"
            alt=""
          />

          {/* 2nd div */}
          <div className="lg:flex lg:justify-between lg:items-center md:flex md:justify-between md:items-center lg:mt-10 md:mt-10 mt-2 lg:mb-7 md:mb-7 mb-3">
            <div className="flex items-center gap-3 py-3">
              <img
                className="w-[50px] h-[50px] instructor-img rounded-full"
                src="https://secure.gravatar.com/avatar/fe53264b3ea357c295e5f3df6530c65e?s=45&d=mm&r=g"
                alt=""
              />
              <div>
                <p className="text-[15px] civil-Institute font-extrabold">
                  Civil Dynamic Institute
                </p>
                <p style={{ color: "#697584" }} className="text-[13px] civil-Instructor">
                  Civil Engineering Instructor
                </p>
              </div>
            </div>
            <p className="flex items-center gap-2">
              <Stars />
              <span>{reviewsData.length} Reviews</span>
            </p>
            <h1
              style={{ color: "var(--orange-color)" }}
              className="lg:text-2xl md:text-2xl text-lg font-extrabold"
            >
              {/* ₹120000 */}
            </h1>
          </div>

          <h1 className="lg:mb-12 md:mb-12 course-detail-program-heading lg:text-3xl md:text-3xl mb-3 font-extrabold">
            Civil Engineering Job Preparation Program
          </h1>
          <div
            style={{ backgroundColor: "#F6F6F6" }}
            className={`lg:py-4 md:py-4 py-2 lg:pl-4 md:pl-4 pl-2 rounded-xl`}
          >
            {buttonsData.map((button) => (
              <button
                style={
                  active === button
                    ? { backgroundColor: "var(--orange-color)", color: "white" }
                    : { backgroundColor: "white", color: "black" }
                }
                className={`course-detail-overview-buttons lg:px-4 md:px-4 px-2 lg:py-2 md:py-2 py-2 mt-1 mb-1 rounded-lg lg:mr-2 md:mr-2 mr-[4px] font-extrabold lg:text-lg md:text-lg text-[10px]`}
                onClick={() => setActive(button)}
              >
                {button}
              </button>
            ))}
          </div>
          {active === "Overview" && <Overview />}
          {active === "Curriculum" && <Curriculum />}
          {active === "Reviews" && <Reviews />}
          {active === "Instructor" && <Instructor />}
        </div>

        {/* right div */}
        <div
          style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
          className="flex-[0.4] course-detail-right-div lg:p-8 md:p-8 p-3 h-max rounded-xl mt-12"
        >
          <h1 className="lg:text-2xl course-detail-right-heading  md:text-2xl text-xl font-extrabold lg:mb-5 md:mb-5 mb-3">
            Course Features
          </h1>
          <div
            style={{
              borderTop: "2px solid var(--orange-color)",
              borderStyle: "dashed",
            }}
          >
            {courseFeaturesData.map((ele) => (
              <div className="right-info-box flex justify-between items-center  lg:py-7 md:py-7 py-4 lg:text-xl md:text-xl text-sm">
                <div className="flex items-center gap-2">
                  <p>{ele.icon}</p>
                  <span style={{ color: "#697584" }}>{ele.features}:</span>
                </div>
                <p className="font-extrabold">{`${ele.time}`}</p>
              </div>
            ))}
          </div>
          
          <button href="https://wa.me/9124732888" target="_blank"
            style={{ backgroundColor: "var(--orange-color)" }}
            className="contact-btn text-white text-[22px] font-bold rounded-lg mt-5 w-full py-3"
          >
          
            Contact Us
          </button>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
