import React from "react";
import "./Contact.css";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { Helmet } from "react-helmet";
function Contact() {
  const openMap = () => {
    const address =
      "Plot No : 363, Near Tamando Overbridge Bijipur, Bhubaneswar, Odisha‐752054";
    const encodedAddress = encodeURIComponent(address);
    const mapsLink = `https://www.google.com/maps?q=${encodedAddress}`;
    window.open(mapsLink, "_blank");
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Civil Dynamic-Contact Us</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="container mt-32 m-auto">
        <div className="content">
          <div className="left-side">
            <div className="details">
              <FaMapMarkerAlt
                className="icons cursor-pointer"
                onClick={openMap}
              />
              <h2 className="topic">Address</h2>
              <p className="text-one">
                Plot No : 363, Near Tamando Overbridge Bijipur
              </p>
              <p className="text-two">Bhubaneswar, Odisha‐752054</p>
            </div>
            <div className="details">
              <a href="https://wa.me/9124732888" target="_blank">
                <FaPhoneAlt className="icons" />
              </a>

              <div>
                <h2 className="topic">Phone</h2>
                <p className="text-one">+91 9124732888 </p>
                <p className="text-two">+91 9124717888</p>
              </div>
            </div>
            <div className="details">
              <a href="mailto:civildynamic1@gmail.com" target="_blank">
                <FaEnvelope className="icons cursor-pointer" />
              </a>

              <div>
                <h2 className="topic">Email</h2>
                <p className="text-one">civildynamic1@gmail.com</p>
                <p className="text-two">civildynamic1@gmail.com</p>
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="topic-text">Get in Touch</div>
            <p>Have any Questions? We'd love to hear from you.</p>
            <form>
              <div className="input-box">
                <input type="text" placeholder="Your Name" />
              </div>
              <div className="input-box">
                <input type="text" placeholder="Your Email" />
              </div>
              <div className="input-box">
                <input type="tel" placeholder="Your Phone Number" />
              </div>
              <div className="input-box message-box">
                <textarea type="text" placeholder="Write a Message" />
              </div>
              <div className="flex items-center gap-10">
                <a
                  href="https://wa.me/9124732888"
                  target="_blank"
                  className="send-button"
                >
                  Send a Message
                </a>

                <a href="https://wa.me/9124732888" target="_blank">
                  <RiWhatsappFill size={45} color="#52cd60" cursor="pointer" />
                </a>
              </div>
            </form>
          </div>
        </div>
        <div className="shadow-xl mt-8">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15724.791763682286!2d85.82220624912896!3d20.29586441645804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a784a25185e3%3A0x7aaf49b365674a32!2sBijipur%2C%20Bhubaneswar%2C%20Odisha%20752054!5e0!3m2!1sen!2sin!4v1666060917232!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Contact;
