import React from "react";
import Stars from "../../Testimonial/Stars";
import "../CourseDetails.css";
import { reviewsData } from "../ReviewData";
const Reviews = () => {

  
  return (
    <div>
      <h1 className="text-xl font-extrabold my-9">{reviewsData.length} Reviews</h1>
      {
        reviewsData.map((review, index)=>(
          <div key={index} className="flex lg:items-center md:items-center lg:gap-8 md:gap-8 gap-3 py-3">
        <img
          className="lg:w-[180px] lg:h-[180px] md:w-[180px] md:h-[180px] w-[50px] h-[50px] rounded-full"
          src={review.profileImage}
          alt={`Profile of ${review.name}`}
        />
        <div>
          <div className="flex justify-between">
            <p className="lg:text-[20px] md:text-[20px] text-[15px] font-extrabold">
            {review.name}
            </p>
            <Stars />
          </div>
          <p className="content lg:mt-2 md:mt-2 mt-1 font-bold lg:text-base md:text-base text-[12px]">
          {review.reviewText}
          </p>
        </div>
      </div>
        ))
      }
     
      
    </div>
  );
};

export default Reviews;
