import React from "react";
import HeroSection from "./Herosection/HeroSection";
import Courses from "./SecHeroSection/Courses";
import About from "./About/About";
import Testimonials from "./Testimonial/Testimonials";
import Team from "./TeamMembers/OurTeam";
import { Helmet } from "react-helmet";
const Home = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Civil Dynamic - Your Path to a High-Paying Civil Engineering Job</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <HeroSection />
      <Courses />
      <About />
      <Testimonials />
      <Team />
    </div>
  );
};

export default Home;
