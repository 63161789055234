import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import "./Navbar.css";
import { RiMenu2Fill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import {
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiOutlineMail,
  AiOutlineYoutube,
  AiOutlineFacebook,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import LoginModal from "../Login/LoginModal";
const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [menu, setMenu] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 30) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const toggleMenuBar = () => {
    setMenu(!menu);
  };

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
    setMenu(false);
  };

  const hideNavItems = () => {
    setMenu(false);
  };

  return (
    <div
    
      className={`w-[100%] max-w-[2600px]  h-[80px] responsive-header pl-6 pr-20   ${
        isScrolled ? "scrolled" : "no-scrolled"
      } 
      }overflow-hidden  ${menu ? "bg-white" : ""} fixed top-0 z-10`}
    >
      <header className="h-full w-full box-border flex justify-between items-center">
        <img
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
          src={logo}
          alt=""
          className={`brightness-150 contrast-200 ${
            isMobile ? "w-[130px] h-[30px]" : "w-[230px] h-[55px] mb-3 logo-img"
          }  `}
        />
        {isMobile && menu && (
          <RxCross2
            cursor="pointer"
            color={isScrolled ? "black" : "white"}
            size={25}
            onClick={toggleMenuBar}
          />
        )}
        {isMobile && !menu && (
          <RiMenu2Fill
            cursor="pointer"
            size={25}
            color={isScrolled ? "black" : "white"}
            onClick={toggleMenuBar}
          />
        )}
        <ul
          onClick={hideNavItems}
          className={
            isMobile
              ? `flex flex-col gap-5 nav-bg  absolute h-[43vh] top-[50px] ${
                  !menu ? "-right-[100%] " : "right-0 left-0"
                } items-center  text-lg cursor-pointer scrolled`
              : `flex justify-between items-center gap-10 nav-text ${
                  isScrolled ? "text-[var(--logo-color)]" : "text-white"
                }`
          }
        >
          <Link
            to="/course-page"
            className={`${isScrolled ? "nav-item" : "nav-item-hover"} ${
              isMobile ? "mt-4" : ""
            } `}
          >
            Courses
          </Link>
          <Link
            to="/about"
            className={`${isScrolled ? "nav-item" : "nav-item-hover"} `}
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className={`${isScrolled ? "nav-item" : "nav-item-hover"} `}
          >
            {" "}
            Contact Us
          </Link>
          {menu ? (
            <li
              className={`${isScrolled ? "nav-item" : "nav-item-hover"} `}
              onClick={toggleLoginModal}
            >
              {" "}
              Login
            </li>
          ) : (
            <li>
              <button
                className={` ${
                  isScrolled || isMobile ? "hover-login-btn" : "login-btn"
                } py-1 px-5`}
                onClick={toggleLoginModal}
              >
                Login
              </button>{" "}
            </li>
          )}

          {isMobile && (
            <li className="flex gap-3 mt-4">
              <a href="https://wa.me/9124732888" target="_blank">
                <AiOutlineWhatsApp className="nav-social-icon" />
              </a>

              <a
                href="https://www.instagram.com/civil_dynamic/"
                target="_blank"
              >
                <AiOutlineInstagram className="nav-social-icon" />
              </a>
              <a href="mailto:civildynamic1@gmail.com" target="_blank">
                <AiOutlineMail className="nav-social-icon" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61552953963690"
                target="_blank"
              >
                <AiOutlineFacebook className="nav-social-icon" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC27fZEy5Z1TPjag6MvC_Zaw"
                target="_blank"
              >
                <AiOutlineYoutube className="nav-social-icon" />
              </a>
            </li>
          )}
        </ul>
      </header>
      {showLoginModal && <LoginModal onClose={toggleLoginModal} />}
    </div>
  );
};

export default Navbar;
