import { toast } from "react-toastify";
import axios from "axios";

export async function handleLoginform(e,setLoading,onClose,LoginData) {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await axios.post("http://localhost:8000/login", LoginData);
      console.log(res);
      toast.success("You have successfully logged in!");
      onClose();
    } catch (error) {
      toast.error("Login failed. Please try again.");
      console.log(error);
    }
    setLoading(false);
    e.target.reset();
  }