import Stars from "./Stars";


const TestimonialCard = ({ name, role, imageSrc, content }) => (
    <div style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset"}} className="flex flex-col lg:w-[95%] md:w-[95%] w-full">
      <div class="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
        <div class="flex-1">
          <Stars />
          <blockquote class="flex-1 mt-8">
            <p class="text-lg leading-relaxed text-gray-900 font-pj">{content}</p>
          </blockquote>
        </div>
        <div class="flex items-center mt-8">
          <img class="flex-shrink-0 object-cover rounded-full w-11 h-11" src={imageSrc} alt="" />
          <div class="ml-4">
            <p class="text-base font-bold text-gray-900 font-pj">{name}</p>
            <p class="mt-0.5 text-sm font-pj text-gray-600">{role}</p>
          </div>
        </div>
      </div>
    </div>
  );

  export default TestimonialCard;